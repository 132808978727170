/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import TimelineItem from "examples/Timeline/TimelineItem";

import { useAuthFetch } from "hooks";

function gen_taula(events) {
  let taula = {
    labels: ["Gen-Mar", "Abr-Jun", "Jul-Set", "Oct-Des"],
    datasets: [{ label: "Esdeveniments", color: "info", data: [] }],
  };
  taula["datasets"][0]["data"].push(
    events.filter(
      (event) =>
        0 <= new Date(event["datetime"]).getMonth() && new Date(event["datetime"]).getMonth() <= 2
    ).length
  );
  taula["datasets"][0]["data"].push(
    events.filter(
      (event) =>
        3 <= new Date(event["datetime"]).getMonth() && new Date(event["datetime"]).getMonth() <= 5
    ).length
  );
  taula["datasets"][0]["data"].push(
    events.filter(
      (event) =>
        6 <= new Date(event["datetime"]).getMonth() && new Date(event["datetime"]).getMonth() <= 8
    ).length
  );
  taula["datasets"][0]["data"].push(
    events.filter(
      (event) =>
        9 <= new Date(event["datetime"]).getMonth() && new Date(event["datetime"]).getMonth() <= 11
    ).length
  );
  return taula;
}

function LastEventsChart() {
  const [taula, setTaula] = useState([]);
  const [events, setEvents] = useState([]);
  const { send: sendEvents, response: responseEvents, isLoading, error } = useAuthFetch();
  const any = new Date().getFullYear().toString();

  useEffect(() => {
    sendEvents(
      "get",
      "/events",
      {},
      {
        start: new Date(new Date().getFullYear().toString()).toISOString(),
        end: new Date((new Date().getFullYear() + 1).toString()).toISOString(),
        order: "asc",
      }
    );
  }, []);

  useEffect(() => {
    if (responseEvents != null) {
      setTaula(gen_taula(responseEvents));
    }
  }, [responseEvents]);

  console.log(events);
  return (
    <VerticalBarChart
      icon={{ color: "info", component: "leaderboard" }}
      color="success"
      title="Esdeveniments per trimestre"
      description={new Date().getFullYear()}
      chart={taula}
    />
  );
}

export default LastEventsChart;
