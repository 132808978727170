import * as React from "react";
import { ButtonGroup, Button } from "@mui/material";
import { Add, ArrowLeft, ArrowRight, Visibility } from "@mui/icons-material";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
//background: linear-gradient(195deg, #49a3f1, #1A73E8);
const NavButtonGroup = styled(ButtonGroup)`
  button {
    border: 1px solid rgba(233, 30, 99, 0.5);
    border-radius: 0.75rem;
    border-color: #f0f2f5;
    background: linear-gradient(195deg, #5a7bb3, #405780);
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
      0rem 0.4375rem 0.625rem -0.3125rem rgba(52, 71, 103, 0.4);
    border-bottom: 0;
    border-top: 0;
  }

  svg {
    fill: #ffffff;
  }

  .today-btn {
    color: #ffffff;
    font-weight: bold;
  }

  .month-btn {
    color: #ffffff;
    font-weight: bold;
  }

  .week-btn {
    color: #ffffff;
    font-weight: bold;
  }

  .day-btn {
    color: #ffffff;
    font-weight: bold;
  }

  .agenda-btn {
    color: #ffffff;
    font-weight: bold;
  }
`;

export const navigateContants = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

export const views = {
  MONTH: "month",
  WEEK: "week",
  WORK_WEEK: "work_week",
  DAY: "day",
  AGENDA: "agenda",
};

function CalendarToolbar(Props) {
  const { label, onNavigate, onView, onSetSelectedCalendarsClicked, onOpenConfigClicked } = Props;
  function navigate(action) {
    onNavigate(action);
  }
  function view(view) {
    onView(view);
  }

  return (
    <Wrapper>
      <NavButtonGroup variant="outlined">
        <Button onClick={navigate.bind(null, navigateContants.PREVIOUS)}>
          <ArrowLeft />
        </Button>
        <Button className="today-btn" onClick={navigate.bind(null, navigateContants.TODAY)}>
          Avui
        </Button>
        <Button onClick={navigate.bind(null, navigateContants.NEXT)}>
          <ArrowRight />
        </Button>
      </NavButtonGroup>

      <span className="rbc-toolbar-label">
        <b>{label}</b>
      </span>

      <NavButtonGroup variant="outlined">
        <Button className="month-btn" onClick={view.bind(null, views.MONTH)}>
          MES
        </Button>
        <Button className="week-btn" onClick={view.bind(null, views.WEEK)}>
          SETMANA
        </Button>
        <Button className="day-btn" onClick={view.bind(null, views.DAY)}>
          DIA
        </Button>
        <Button className="agenda-btn" onClick={view.bind(null, views.AGENDA)}>
          AGENDA
        </Button>
        <Button onClick={() => onSetSelectedCalendarsClicked()}>
          <Visibility />
        </Button>
        <Button onClick={() => onOpenConfigClicked()}>
          <Add />
        </Button>
      </NavButtonGroup>
    </Wrapper>
  );
}
export default CalendarToolbar;
