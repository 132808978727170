import { useState } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router";
import useAuthContext from "./useAuthContext";

const useLogout = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [, dispatch] = useAuthContext();
  const navigate = useNavigate();

  const logout = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.delete(process.env.REACT_APP_BASE_ADDRESS + "/auth/logout", {
        withCredentials: true,
      });

      if (response.status === 200) {
        dispatch({ type: "LOGOUT" });
        setIsLoading(false);
        navigate("/authentication/sign-in");
      } else {
        setIsLoading(false);
        setError("Request failed with status " + response.status);
      }
    } catch (err) {
      setIsLoading(false);
      setError(
        err.response?.data.msg ? err.response.data.msg : `An error occurred: ${err.message}`
      );
    }
  };
  return { logout, isLoading, error };
};

export default useLogout;
