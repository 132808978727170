/** 
  All of the routes for the PIMPAM are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// PIMPAM layouts
import Dashboard from "layouts/dashboard";
import Events from "layouts/events";
import Autoliquidation from "layouts/autoliquidation";
import Entitats from "layouts/entitats";
import Billing from "layouts/billing";
import Calendari from "layouts/calendar";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import ProtectedRoute from "components/ProtectedRoute";

const routes = [
  {
    type: "collapse",
    name: "Panell de control",
    key: "panell-de-control",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/panell-de-control",
    component: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Calendari",
    key: "calendari",
    icon: <Icon fontSize="small">calendar_month</Icon>,
    route: "/calendari",
    component: (
      <ProtectedRoute>
        <Calendari />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Entitats",
    key: "entitats",
    icon: <Icon fontSize="small">diversity_3</Icon>,
    route: "/entitats",
    component: (
      <ProtectedRoute>
        <Entitats />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">local_activity</Icon>,
    route: "/events",
    component: (
      <ProtectedRoute>
        <Events />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Autoliquidació",
    key: "autoliquidation",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/autoliquidation",
    component: (
      <ProtectedRoute>
        <Autoliquidation />
      </ProtectedRoute>
    ),
  },
  {
    name: "Notificacions",
    key: "notificacions",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notificacions",
    component: (
      <ProtectedRoute>
        <Notifications />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Perfil",
    key: "perfil",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/perfil",
    component: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
