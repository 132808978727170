import { useState } from "react";
//import { useAuthContext } from './useAuthContext';
import axios, { AxiosError } from "axios";
import useAuthContext from "./useAuthContext";
import { useNavigate } from "react-router-dom";
import { replace } from "stylis";

export const useAuthFetch = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [, dispatch] = useAuthContext();
  const navigate = useNavigate();

  const send = async (method, direction, body, params) => {
    setIsLoading(true);
    setError(null);

    const axiosConfig = {
      method, // HTTP request method (e.g., 'get', 'post', 'put', 'delete')
      url: process.env.REACT_APP_BASE_ADDRESS + direction, // Request URL
      data: body, // Request data (for POST and PUT requests)
      params, // URL query parameters (for GET requests)
      headers: {
        "Content-Type": "application/json", // Custom request headers
      },
      timeout: 5000, // Request timeout in milliseconds
      withCredentials: true, // Include credentials (e.g., cookies) with the request
    };

    axios(axiosConfig)
      .then((response) => {
        // Handle the response
        if (response.status === 200) {
          setResponse(response.data);
          setIsLoading(false);
        } else {
          setError("Request failed with status " + response.status);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.status === 418) {
          setError("Not authenticated, login and try again");
          dispatch({ type: "LOGOUT" });
          navigate("/authentication/sign-in", replace);
          setIsLoading(false);
        }
        // Handle errors
        setIsLoading(false);
        setError(
          error.response?.data.msg ? error.response.data.msg : `An error occurred: ${error.message}`
        );
      });
  };

  const sendFile = async (direction, file) => {
    setIsLoading(true);
    setError(null);
    const formData = new FormData();
    console.log("Senfing file: " + file);
    console.log("Form data: " + formData);
    formData.append("file", file);
    axios
      .post(process.env.REACT_APP_BASE_ADDRESS + direction, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 5000, // Request timeout in milliseconds
        withCredentials: true, // Include credentials (e.g., cookies) with the request
      })
      .then((response) => {
        if (response.status === 200) {
          setResponse(response.data);
          setIsLoading(false);
        } else {
          setError("Request failed with status " + response.status);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.status === 418) {
          setError("Not authenticated, login and try again");
          dispatch({ type: "LOGOUT" });
          navigate("/authentication/sign-in", replace);
          setIsLoading(false);
        }
        // Handle errors
        setIsLoading(false);
        setError(
          error.response?.data.msg ? error.response.data.msg : `An error occurred: ${error.message}`
        );
      });
  };

  return { send, sendFile, isLoading, error, response };
};
