import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import { styled } from "@mui/system";

const ITEM_HEIGHT = 55;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const StyledMenuItem = styled(MenuItem)`
  .MuiTypography-root {
    font-size: 15px;
  }

  .MuiCheckbox-root {
    padding: 0px;
    padding-right: 16px;
    padding-bottom: 6px;
  }

  .MuiListItemText-root {
    padding-bottom: 6px;
  }
`;
export default function MultipleSelectCheckmarks({ values, onChange }) {
  if (!values) return null;
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <FormControl sx={{ width: 500 }}>
        <InputLabel id="demo-multiple-checkbox-label">Entitat o entitats</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          style={{ height: "44.125px" }}
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Entitat o entitats" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {values.map((name) => (
            <StyledMenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

MultipleSelectCheckmarks.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};
