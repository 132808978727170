/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import FileList from "examples/Lists/FileList";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import NextEvents from "layouts/dashboard/components/NextEvents";
import LastEventsChart from "layouts/dashboard/components/LastEventsChart";
import EntitiesPieChart from "layouts/dashboard/components/EntitiesPieChart";
import LastFiles from "layouts/dashboard/components/LastFiles";

import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useAuthFetch } from "hooks";

function Dashboard() {
  const [entitats, setEntitats] = useState([]);
  const [totalEvents, setTotalEvents] = useState([]);
  const [eventsMes, setEventsMes] = useState([]);
  const [eventsTrimestre, setEventsTrimestre] = useState([]);
  const [eventsTrimestrePassat, setEventsTrimestrePassat] = useState([]);
  const [eventsMesPassat, setEventsMesPassat] = useState([]);
  const [fitxers, setFitxers] = useState([]);
  //const [properEvent, setProperEvent] = useState([]);
  const { sales, tasks } = reportsLineChartData;
  const { send: sendEvents, response: responseEvents, isLoading, error } = useAuthFetch();
  const { send: sendEntities, response: responseEntities } = useAuthFetch();

  useEffect(() => {
    sendEvents(
      "get",
      "/events",
      {},
      {
        start: new Date((new Date().getFullYear() - 1).toString()).toISOString(),
        end: new Date((new Date().getFullYear() + 1).toString()).toISOString(),
        order: "asc",
      }
    );
  }, []);

  useEffect(() => {
    sendEntities("get", "/entities", {}, {});
  }, []);

  useEffect(() => {
    if (responseEvents != null) {
      setTotalEvents(
        responseEvents.filter(
          (event) => new Date(event["datetime"]).getFullYear() == new Date().getFullYear()
        ).length
      );
      setEventsMes(
        responseEvents.filter(
          (event) => new Date(event["datetime"]).getMonth() == new Date().getMonth()
        ).length
      );
      setEventsTrimestre(
        responseEvents.filter(
          (event) =>
            Math.floor(new Date().getMonth() / 3) * 3 <= new Date(event["datetime"]).getMonth() &&
            new Date(event["datetime"]).getMonth() <= Math.floor(new Date().getMonth() / 3) * 3 + 2
        ).length
      );
      setEventsTrimestrePassat(
        responseEvents.filter(
          (event) =>
            Math.floor(new Date(new Date().setMonth(-1)).getMonth() / 3) * 3 <=
              new Date(event["datetime"]).getMonth() &&
            new Date(event["datetime"]).getMonth() <=
              Math.floor(new Date(new Date().setMonth(-1)).getMonth() / 3) * 3 + 2
        ).length
      );
      setEventsMesPassat(
        responseEvents.filter(
          (event) =>
            new Date(event["datetime"]).getMonth() == new Date(new Date().setMonth(-1)).getMonth()
        ).length
      );
      //setProperEvent(responseEvents[0]);
    } else {
      setTotalEvents("-");
      setEventsMes("-");
      //setProperEvent(null);
    }
  }, [responseEvents]);

  useEffect(() => {
    if (responseEntities != null) {
      setEntitats(responseEntities.length);
    } else {
      setEntitats("-");
    }
  }, [responseEntities]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="groups"
                title="Entitats"
                count={entitats}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "entitats al sistema",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="calendar_month"
                title="Events aquest mes"
                count={eventsMes}
                percentage={{
                  color: eventsMes > eventsMesPassat ? "success" : "error",
                  amount:
                    (eventsMes != "-"
                      ? eventsMesPassat == 0
                        ? 0
                        : Math.floor(((eventsMes - eventsMesPassat) * 100) / eventsMesPassat)
                      : "-") + "%",
                  label: "que el mes passat",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="calendar_month"
                title="Events aquest trimestre"
                count={eventsTrimestre}
                percentage={{
                  color: eventsTrimestre > eventsTrimestrePassat ? "success" : "error",
                  amount:
                    (eventsTrimestre != "-"
                      ? eventsTrimestrePassat == 0
                        ? 0
                        : Math.floor(
                            ((eventsTrimestre - eventsTrimestrePassat) * 100) /
                              eventsTrimestrePassat
                          )
                      : "-") + "%",
                  label: "que el mes passat",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="calendar_month"
                title="Events aquest any"
                count={totalEvents}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Actualitzat ara mateix",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <LastEventsChart />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EntitiesPieChart />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <NextEvents />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <MDBox mb={3}>
                <LastFiles />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
