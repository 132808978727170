/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

import { useAuthFetch } from "hooks";

function eventTypeToIcon(type) {
  switch (type) {
    case "APLEC":
      return "diversity_3";
    case "BALLADA":
      return "emoji_people";
    case "CONCERT":
      return "nightlife";
  }
  return "notifications";
}

function NextEvents() {
  const [events, setEvents] = useState([]);
  const { send: sendEvents, response: responseEvents, isLoading, error } = useAuthFetch();

  useEffect(() => {
    sendEvents(
      "get",
      "/events",
      {},
      {
        start: new Date().toISOString(),
        end: new Date(new Date().setDate(new Date().getDate() + 7)),
        order: "asc",
      }
    );
  }, []);

  useEffect(() => {
    console.log(responseEvents);
    if (responseEvents != null) {
      setEvents(responseEvents);
    }
  }, [responseEvents]);

  console.log(events);
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Pròxims esdeveniments
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography variant="button" color="text" fontWeight="medium">
              {events.length}
            </MDTypography>{" "}
            esdeveniments a una setmana vista
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {events.map((event, i) => (
          <TimelineItem
            key={event.id}
            color="info"
            icon={eventTypeToIcon(event.type)}
            title={event.type + " de " + event.entity.name}
            dateTime={new Date(event.datetime).toLocaleString()}
            lastItem={i == events.length - 1}
          />
        ))}
      </MDBox>
    </Card>
  );
}

export default NextEvents;
