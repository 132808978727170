import React, { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes, { func } from "prop-types";
import moment from "moment";
require("moment/locale/ca.js");
import { Calendar, Views, DateLocalizer, momentLocalizer } from "react-big-calendar";
import "layouts/calendar/components/react-big-calendar.css";
import Toolbar from "layouts/calendar/components/Toolbar";
import ConfigModal from "layouts/calendar/components/ConfigModal";
import DisplayedCalendarsModal from "layouts/calendar/components/DisplayedCalendarModal";
import EventModal from "layouts/calendar/components/EventModal";
import EditModal from "layouts/calendar/components/EditModal";
import DeleteModal from "layouts/calendar/components/DeleteModal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useAuthFetch } from "hooks";
import { useAuthContext } from "hooks";
import { Dialog } from "@mui/material";
import { styled } from "@mui/system";

//require("globalize/lib/cultures/globalize.culture.es");

const mLocalizer = momentLocalizer(moment);

const ca = {
  week: "Setmana",
  work_week: "Setmana laborable",
  day: "Dia",
  month: "Mes",
  previous: "Enrere",
  next: "Següent",
  today: "Avui",
  agenda: "Agenda",

  showMore: (total) => `+${total} més`,
};

const StyledCalendar = styled(Calendar)`
  .rbc-month-view {
    border-radius: 0.75rem;
    border: 0px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);

    & > *,
    .rbc-header,
    .rbc-day-bg {
      border-color: #f0f2f5 !important;
    }
  }

  .rbc-time-view {
    border-radius: 0.75rem;
    border: 0px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);

    & > *,
    .rbc-header {
      border-color: #f0f2f5 !important;
      border-top-width: thin;
    }
  }

  .rbc-time-content,
  . {
    border-radius: 6px;
    border-color: #6f7072;
  }

  .rbc-time-gutter,
  .rbc-header-gutter {
    color: #6f7072;
  }

  .rbc-button-link {
    margin-bottom: 10px;
    padding: 10px 10px;
  }

  .rbc-calendar {
    box-sizing: inherit;
    border-color: #6f7072 !important;
  }

  .rbc-day-view {
    border-radius: 0.75rem;
    border: 0px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);

    & > *,
    .rbc-header,
    .rbc-day-bg {
      border-color: #f0f2f5 !important;
    }
  }

  .rbc-agenda-view {
    border-radius: 0.75rem;
    border: 0px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  }

  .rbc-off-range-bg {
    background-color: #e6e6e6;
  }

  .rbc-event {
    height: 28px;
    background-color: #1a73e8;
  }

  .rbc-event-content {
    white-space: no-wrap;
    position: center;
    font-size: initial;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .rbc-event.rbc-selected,
  .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #265985;
    font-size: initial;
  }

  .rbc-today {
    /*background-color: #2a2a2a;*/
  }

  .rbc-month-row {
    /*overflow-y: scroll;*/
    overflow-x: hidden;
  }
  * {
    scrollbar-width: auto;
    scrollbar-color: #5e5e5e rgba(0, 0, 0, 0);
  }

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }

  *::-webkit-scrollbar-thumb {
    background-color: #5e5e5e;
    border-radius: 10px;
    border: 3px solid rgba(0, 0, 0, 0);
  }
`;

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

/**
 * We are defaulting the localizer here because we are using this same
 * example on the main "About" page in Storybook
 */
export default function Calendari({ localizer = mLocalizer, showDemoLink = true, ...props }) {
  const [isConfigModalDisplayed, setIsConfigModalDisplayed] = useState(false);
  const [isSelecteDisplayedModalOpen, setIsSelecteDisplayedModalOpen] = useState(false);
  const [calendars, setCalendars] = useState();
  const [events, setEvents] = useState();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { send: sendEvents, response: responseEvents, isLoading, error } = useAuthFetch();
  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      //defaultDate: new Date(2015, 3, 1),
      //max: dates.add(dates.endOf(new Date(2015, 17, 1), "day"), -1, "hours"),
      views: Object.keys(Views).map((k) => Views[k]),
    }),
    []
  );

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str) => {
    return (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).replace(/_/g, " ");
  };

  useEffect(() => {
    sendEvents("get", "/events", {}, {});
    //sendEvents("post", "/events", {evento objeto}, {})
  }, []);

  useEffect(() => {
    console.log(responseEvents);
    if (responseEvents != null) {
      setEvents(
        responseEvents.map((event) => ({
          id: event.id,
          title: capitalizeFirstLetter(event.type) + " de " + event.entity.name,
          start: new Date(event.datetime),
          end: new Date(new Date(event.datetime).setHours(new Date(event.datetime).getHours() + 1)),
        }))
      );
    }
  }, [responseEvents]);

  //if (events != null) console.log(events);

  const handleEventClick = (event) => {
    console.log("Te muestro: ");
    console.log(event);
    console.log("El evento");
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const onCalendarToggled = (toggled) => {
    let cals = deepCopy(calendars);
    cals.forEach((c) => (c.name === toggled.name ? (c.isDisplayed = !c.isDisplayed) : null));
    setCalendars(cals);
    saveDisplaySettings(cals);
  };

  const onCalendarColorChanged = (calendar, code) => {
    let cals = deepCopy(calendars);
    cals.forEach((c) => (c.name === calendar.name ? (c.color = colors[code]) : null));
    setCalendars(cals);
    saveDisplaySettings(cals);
  };

  const handleEdit = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(true);
  };

  const handleDelete = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(true);
  };

  const reloadEvents = () => {
    sendEvents("get", "/events", {}, {});
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox style={{ height: window.innerHeight - 116 }}>
        <StyledCalendar
          culture={"es"}
          messages={ca}
          defaultDate={defaultDate}
          events={events}
          localizer={localizer}
          max={max}
          showMultiDayTimes
          step={60}
          views={views}
          popup
          onSelectEvent={handleEventClick}
          components={{
            toolbar: (props) => (
              <Toolbar
                {...props}
                onOpenConfigClicked={() => setIsConfigModalDisplayed(true)}
                onSetSelectedCalendarsClicked={() => setIsSelecteDisplayedModalOpen(true)}
              />
            ),
          }}
        />
        <EventModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          event={selectedEvent}
          onEdit={() => handleEdit()}
          onDelete={() => handleDelete()}
        />
        <EditModal
          open={isEditModalOpen}
          eventSelected={selectedEvent}
          handleClose={() => setIsEditModalOpen(false)}
          onEventsUpdated={() => reloadEvents()}
          onUpdateEvent={() => setIsModalOpen(true)}
        />
        <DeleteModal
          open={isDeleteModalOpen}
          eventSelected={selectedEvent}
          handleClose={() => setIsDeleteModalOpen(false)}
          onEventsUpdated={() => reloadEvents()}
          onUpdateEvent={() => setIsModalOpen(true)}
        />
        <ConfigModal
          open={isConfigModalDisplayed}
          handleClose={() => setIsConfigModalDisplayed(false)}
          onEventsUpdated={() => reloadEvents()}
        />
        <DisplayedCalendarsModal
          open={isSelecteDisplayedModalOpen}
          onClose={() => setIsSelecteDisplayedModalOpen(false)}
          onCalendarToggled={onCalendarToggled}
          calendars={calendars}
          onCalendarColorChanged={onCalendarColorChanged}
        />
      </MDBox>
    </DashboardLayout>
  );
}
Calendari.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  showDemoLink: PropTypes.bool,
};
