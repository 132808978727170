import { Navigate, Outlet } from "react-router-dom";
import { PropTypes } from "prop-types";
import { useAuthContext } from "hooks";

const ProtectedRoute = ({ children }) => {
  const [state] = useAuthContext();
  if (!state.isAuthenticated) {
    return <Navigate to="/authentication/sign-in" replace />;
  }
  return children || <Outlet />;
};
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
