import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Delete,
  Theme,
  Button,
  useTheme,
  IconButton,
  DialogTitle,
  Box,
  TextField,
  Dialog,
  OutlinedInput,
  Chip,
  MenuItem,
  InputLabel,
  Stack,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  Grid,
} from "@mui/material";
import { DatePicker, LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { renderDigitalClockTimeView } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ca";
import "dayjs/locale/es";
import { EventType, EventFile, Event, ConcertType } from "layouts/calendar/models/Event";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import AttachFile from "@mui/icons-material/AttachFile";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useAuthFetch } from "hooks";
import { blue, green } from "@mui/material/colors";
import { Send } from "@mui/icons-material";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "pdfjs-dist/build/pdf";
import { Document } from "react-pdf";

import LoadingBackdrop from "components/LoadingBackdrop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AttachButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[500],
  "&:hover": {
    backgroundColor: blue[700],
  },
}));

const SendButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]), // para obtener blanco
  backgroundColor: green[500],
  "&:hover": {
    backgroundColor: green[700],
  },
}));
function ConfigModal({ open, handleClose, onEventsUpdated }) {
  const [type, setType] = useState("");
  const [property, setProperty] = useState("");
  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [province, setProvince] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [entity_id, setEntity_id] = useState("");
  const [entities, setEntities] = useState();
  const [orchestra, setOrchestra] = useState("");
  const [num_orchestras, setNum_orchestras] = useState("");
  const [free, setFree] = useState("");
  const [revenue, setRevenue] = useState("");
  const [concert_type, setConcert_type] = useState("");
  const [sardanes, setSardanes] = useState({
    title1: "",
    author1: "",
    title2: "",
    author2: "",
    title3: "",
    author3: "",
    title4: "",
    author4: "",
    title5: "",
    author5: "",
    title6: "",
    author6: "",
    title7: "",
    author7: "",
    title8: "",
    author8: "",
    title9: "",
    author9: "",
    title10: "",
    author10: "",
  });
  const [uploadStatus, setUploadStatus] = useState(null); // 'success', 'error', or null
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { send: sendEntities, response: responseEntities, isLoading } = useAuthFetch();
  const { send: sendEvent, response: responseEvent } = useAuthFetch();
  const { sendFile: sendEventFile, response: responseEventFile } = useAuthFetch();

  const MARGIN_BOTTOM = 3;

  useEffect(() => {
    sendEntities("get", "/entities", {}, {});
  }, []);

  useEffect(() => {
    console.log("responseEntities changed!");
    console.log(responseEntities);
    if (responseEntities != null) {
      setEntities(
        responseEntities.map((entity) => ({
          id: entity.id,
          name: entity.name,
        }))
      );
    }
  }, [responseEntities]);

  useEffect(() => {
    console.log("responseEvent changed!");
    console.log(responseEvent);
    console.log(uploadedFile);
    if (uploadedFile == null) {
      close();
    } else {
      if (responseEvent != null) {
        sendEventFile("/events/" + responseEvent.id + "/files", uploadedFile);
      }
    }
  }, [responseEvent]);

  useEffect(() => {
    console.log("responseEventFile changed!");
    close();
  }, [responseEventFile]);

  function addEvent() {
    const datetime = new Date();
    console.log(date);
    console.log(startTime);
    datetime.setFullYear(date.year());
    datetime.setMonth(date.month());
    datetime.setDate(date.date());
    datetime.setHours(startTime.hour());
    datetime.setMinutes(startTime.minute());
    datetime.setSeconds(startTime.second());
    console.log(datetime);

    const newEvent = {
      ...Event,
      type: type,
      property: property,
      address: address,
      town: town,
      province: province,
      datetime: datetime,
      entity: {
        id: entity_id,
      },
      orchestra: orchestra,
      num_orchestras: num_orchestras,
      free: free,
      revenue: revenue,
      concert_type: concert_type,
    };

    setLoading(true);
    sendEvent("post", "/events", newEvent, {});
  }

  function close() {
    // reset fields
    setType(EventType.Altres);
    setProperty("");
    setAddress("");
    setTown("");
    setProvince("");
    setDate("");
    setStartTime(dayjs("2023-12-07T10:00"));
    setEntity_id("");
    setOrchestra("");
    setNum_orchestras(null);
    setFree(1);
    setRevenue(null);
    setConcert_type(ConcertType.Petit);
    setUploadStatus(null);
    setUploadedFile(null);
    setUploadError(null);

    // refresh calendars
    onEventsUpdated();

    // close loading backdrop
    setLoading(false);

    // close modal
    handleClose();
  }

  const curdate = new Date();
  const futureDate = curdate.getDate() + 3;
  curdate.setDate(futureDate);
  const defaultValue = curdate.toLocaleDateString("en-CA");

  const handleFileUpload = async (event) => {
    try {
      // Make an API call or perform the upload logic here
      // For simplicity, let's assume a successful upload
      // Replace this with your actual upload logic
      // const response = await yourUploadApi(formData);

      setUploadStatus("success");
      setUploadedFile(event.target.files[0]);
      console.log("PDF ATTACHED:");
      console.log(event.target.files[0]);
      setUploadError(null);
    } catch (error) {
      console.error("Upload error:", error);
      setUploadStatus("error");
      setUploadedFile(null);
      setUploadError("Error al pujar el document. Per favor, intenta-ho un altre cop.");
    }
  };

  // Function to handle changes in text fields
  const handleFieldChange = (fieldName) => (event) => {
    setSardanes((prevValues) => ({
      ...prevValues,
      [fieldName]: event.target.value,
    }));
  };

  return (
    <>
      <LoadingBackdrop open={loading} handleOnClick={() => {}} />
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle sx={{ fontWeight: "bold", alignSelf: "center" }}>Afegir nou event</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <form
            onSubmit={async (e) => {
              console.log("SUBMITTEADO");
              e.preventDefault(); // Prevent the default form submission behavior
              addEvent();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl sx={{ width: 150 }} required>
                  <InputLabel>{"Tipus d'event"}</InputLabel>
                  <Select
                    labelId="type"
                    id="eventType"
                    value={type}
                    label={"Tipus d'event"}
                    required
                    autoWidth
                    sx={{ marginBottom: MARGIN_BOTTOM, minHeight: 44.13 }}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <MenuItem value={EventType.Aplec}>Aplec</MenuItem>
                    <MenuItem value={EventType.Ballada}>Ballada</MenuItem>
                    <MenuItem value={EventType.Concert}>Concert</MenuItem>
                    <MenuItem value={EventType.Concurs}>Concurs</MenuItem>
                    <MenuItem value={EventType.Altres}>Altres actes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl sx={{ width: 300 }} required>
                  <InputLabel id="demo-multiple-chip-label">Entitat organitzadora</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    value={entity_id}
                    onChange={(e) => setEntity_id(e.target.value)}
                    sx={{ marginBottom: MARGIN_BOTTOM, minHeight: 44.13 }}
                    input={<OutlinedInput id="select-entity" label="Entitat organitzadora" />}
                  >
                    {entities &&
                      entities.map((entity) => (
                        <MenuItem
                          key={entity.name}
                          value={entity.id}
                          //style={getStyles(entity.name, entity.name, theme)}
                        >
                          {entity.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {type == EventType.Aplec && (
              <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
                <FormControl required sx={{ width: 225 }}>
                  <InputLabel>Gratuit o pagament</InputLabel>
                  <Select
                    labelId="free"
                    id="free"
                    value={free}
                    label={"Gratuit o pagament"}
                    required
                    autoWidth
                    sx={{ marginBottom: MARGIN_BOTTOM, minHeight: 44.13 }}
                    onChange={(e) => {
                      setFree(e.target.value);
                      setRevenue(null);
                    }}
                  >
                    <MenuItem value={1}>Gratuit</MenuItem>
                    <MenuItem value={0}>Pagament</MenuItem>
                  </Select>
                </FormControl>
                {free == 0 && (
                  <TextField
                    type="number"
                    variant="outlined"
                    color="secondary"
                    label="Ingresos (sense IVA)"
                    disabled={free == 1}
                    onChange={(e) => setRevenue(e.target.value)}
                    value={revenue}
                    sx={{ width: 250 }}
                    required
                  />
                )}
              </Stack>
            )}
            {type == EventType.Concurs && (
              <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
                <TextField
                  type="number"
                  variant="outlined"
                  color="secondary"
                  label="Número de cobles"
                  onChange={(e) => setNum_orchestras(e.target.value)}
                  value={num_orchestras}
                  sx={{ width: 250 }}
                  required
                />
              </Stack>
            )}
            {type === EventType.Concert && (
              <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
                <FormControl required sx={{ width: 200 }}>
                  <InputLabel>Tipus de concert</InputLabel>
                  <Select
                    labelId="concert_type"
                    id="concert_type"
                    value={concert_type}
                    label={"Tipus de concert"}
                    required
                    autoWidth
                    sx={{ marginBottom: MARGIN_BOTTOM, minHeight: 44.13 }}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setConcert_type(e.target.value);
                      setFree(1);
                      setRevenue(null);
                    }}
                  >
                    <MenuItem value={ConcertType.Petit}>Petit</MenuItem>
                    <MenuItem value={ConcertType.Gran}>Gran</MenuItem>
                  </Select>
                </FormControl>
                {concert_type === ConcertType.Gran && (
                  <FormControl required sx={{ width: 200 }}>
                    <InputLabel>Gratuit o pagament</InputLabel>
                    <Select
                      labelId="free"
                      id="free"
                      value={free}
                      label={"Gratuit o pagament"}
                      required
                      autoWidth
                      sx={{ marginBottom: MARGIN_BOTTOM, minHeight: 44.13 }}
                      onChange={(e) => {
                        setFree(e.target.value);
                        setRevenue(null);
                      }}
                    >
                      <MenuItem value={1}>Gratuit</MenuItem>
                      <MenuItem value={0}>Pagament</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {concert_type === ConcertType.Gran && free === 0 && (
                  <TextField
                    type="number"
                    variant="outlined"
                    color="secondary"
                    label="Ingresos (sense IVA)"
                    disabled={free == 1}
                    onChange={(e) => setRevenue(e.target.value)}
                    value={revenue}
                    sx={{ width: 200 }}
                    required
                  />
                )}
              </Stack>
            )}
            <p style={{ marginBottom: 10 }}> {"Ubicació de l'event"} </p>
            <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Local"
                onChange={(e) => setProperty(e.target.value)}
                value={property}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Adreça"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                fullWidth
                required
                sx={{ marginBottom: MARGIN_BOTTOM }}
              />
            </Stack>
            <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Ciutat/Poble"
                onChange={(e) => setTown(e.target.value)}
                value={town}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Provincia"
                onChange={(e) => setProvince(e.target.value)}
                value={province}
                fullWidth
                required
              />
            </Stack>
            <p style={{ marginBottom: 10 }}> {"Data i hora de l'event"} </p>
            <Grid container spacing={2} sx={{ marginBottom: MARGIN_BOTTOM }}>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                  <DatePicker
                    label="Data"
                    required
                    onChange={(e) => setDate(e)}
                    slotProps={{ textField: { variant: "outlined" } }}
                    fullWidth
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                  <MobileTimePicker
                    label="Hora d'inici"
                    ampm={false}
                    required
                    onChange={(newValue) => setStartTime(newValue)}
                    slotProps={{ textField: { variant: "outlined" } }}
                    fullWidth
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <p style={{ marginBottom: 10 }}> {"Artistes involucrats"} </p>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Orquestra o cobla"
              onChange={(e) => setOrchestra(e.target.value)}
              value={orchestra}
              required
            />
            <p style={{ marginBottom: 10, marginTop: 20 }}> {"Llistat d'obres interpretades"} </p>
            <Stack spacing={2} direction="row" sx={{ marginBottom: 1 }}>
              <TextField
                label="Titol 1"
                value={sardanes.title1}
                onChange={handleFieldChange("title1")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Autor 1"
                value={sardanes.author1}
                onChange={handleFieldChange("author1")}
                fullWidth
                margin="normal"
              />
            </Stack>
            {sardanes.title1 && sardanes.author1 && (
              <Stack spacing={2} direction="row" sx={{ marginBottom: 1 }}>
                <TextField
                  label="Titol 2"
                  value={sardanes.title2}
                  onChange={handleFieldChange("title2")}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Autor 2"
                  value={sardanes.author2}
                  onChange={handleFieldChange("author2")}
                  fullWidth
                  margin="normal"
                />
              </Stack>
            )}
            {sardanes.title2 && sardanes.author2 && (
              <Stack spacing={2} direction="row" sx={{ marginBottom: 1 }}>
                <TextField
                  label="Titol 3"
                  value={sardanes.title3}
                  onChange={handleFieldChange("title3")}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Autor 3"
                  value={sardanes.author3}
                  onChange={handleFieldChange("author3")}
                  fullWidth
                  margin="normal"
                />
              </Stack>
            )}
            {uploadStatus && (
              <div style={{ marginTop: 30 }}>
                {uploadStatus === "success" ? (
                  <p style={{ color: "green" }}>
                    Document adjuntat amb èxit! Arxiu: {uploadedFile.name}, Tamany:
                    {uploadedFile.size / 1000}
                    KBytes
                  </p>
                ) : (
                  <p style={{ color: "red" }}>{uploadError || "Error uploading the file."}</p>
                )}
              </div>
            )}
            <Stack spacing={48} direction="row" sx={{ marginTop: 4 }}>
              <Grid item xs={3}>
                <AttachButton
                  sx={{ alignSelf: "left" }}
                  component="label"
                  variant="contained"
                  startIcon={<AttachFile />}
                  accept=".pdf"
                >
                  Adjuntar arxiu
                  <VisuallyHiddenInput type="file" accept=".pdf" onChange={handleFileUpload} />
                </AttachButton>
              </Grid>
              <Grid item xs={4}>
                <SendButton
                  type="submit"
                  sx={{ position: "center" }}
                  variant="contained"
                  color="success"
                  endIcon={<SendIcon />}
                >
                  Registrar event
                </SendButton>
              </Grid>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

// PropTypes validation
ConfigModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onEventsUpdated: PropTypes.func.isRequired,
};

export default ConfigModal;
