import React from "react";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { Color } from "layouts/calendar/theme/colors";

const Wrapper = styled("div")`
  display: flex;

  .color {
    width: 20px;
    height: 20px;
  }

  .color-left {
    border-radius: 5px 0px 0px 5px;
  }

  .color-right {
    border-radius: 0px 5px 5px 0px;
    width: 20px;
    height: 20px;
  }
`;

function ColorPickerOption({ color }) {
  return (
    <Wrapper>
      <div className="color color-left" style={{ backgroundColor: color.pillHex }}></div>
      <div className="color color-right" style={{ backgroundColor: color.cardHex }}></div>
    </Wrapper>
  );
}

ColorPickerOption.propTypes = {
  color: PropTypes.shape({
    pillHex: PropTypes.string,
    cardHex: PropTypes.string,
  }).isRequired,
};

export default ColorPickerOption;
