/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import FileList from "examples/Lists/FileList";
import dayjs from "dayjs";

import { useAuthFetch } from "hooks";

function eventTypeToIcon(type) {
  switch (type) {
    case "APLEC":
      return "diversity_3";
    case "BALLADA":
      return "emoji_people";
    case "CONCERT":
      return "nightlife";
    case "ALTRES_ACTES":
      return "library_music";
  }
  return "notifications";
}

function gen_files(reports, entities) {
  let files = [];

  for (let i = 0; i < reports.length && i < 10; i++) {
    let event = reports[i]["type"] == "SGAE_EVENT";
    let excel = reports[i]["type"] == "EXCEL_AUTOLIQUIDATION";
    console.log(event);
    let entitat = event
      ? reports[i]["entity_name"]
      : excel
      ? ""
      : entities.filter((entity) => entity["id"] == reports[i]["entity"]["id"])[0]["name"];

    let file = {
      color: event ? "info" : excel ? "success" : "warning",
      icon: event
        ? eventTypeToIcon(reports[i]["event_type"])
        : excel
        ? "grid_on"
        : "picture_as_pdf",
      name: event
        ? reports[i]["event_type"] + " de " + entitat
        : excel
        ? "Excel Autoliquidacio"
        : "Autoliquidació de " + entitat,
      description: "Generat " + new Date(reports[i]["creation_date"]).toLocaleString(),
      id: reports[i]["id"],
      filename:
        (excel ? "Excel Autoliquidació " : reports[i]["event_type"] + " de " + entitat + " ") +
        dayjs(reports[i]["creation_date"]).format("DD/MM/YYYY"),
      action: {
        type: "download",
        route: "/reports/" + reports[i]["id"],
        color: "info",
        label: "Descarrega",
      },
    };

    files.push(file);
  }

  return files;
}

function LastFiles() {
  const [files, setFiles] = useState([]);
  const { send: sendReports, response: responseReports, isLoading, error } = useAuthFetch();
  const { send: sendEntities, response: responseEntities } = useAuthFetch();

  useEffect(() => {
    sendReports(
      "get",
      "/reports",
      {},
      {
        order: "desc",
        //limit: "8",
        //offset: "0",
      }
    );
  }, []);

  useEffect(() => {
    sendEntities("get", "/entities", {}, {});
  }, []);

  useEffect(() => {
    if (responseReports != null && responseEntities != null) {
      setFiles(gen_files(responseReports, responseEntities));
    } else {
      setFiles(gen_files([], []));
    }
  }, [responseReports, responseEntities]);

  return <FileList title="fitxers recents" files={files} />;
}

export default LastFiles;
