/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

// Data
import ConfigModal from "./components/ConfigModal";
import EditModal from "./components/EditModal";
import DeleteModal from "./components/DeleteModal";
import ImportModal from "./components/ImportModal";
import PropTypes from "prop-types";
import { useAuthFetch } from "hooks";

//MUI icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { blue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import { FilterList } from "@mui/icons-material";
import CustomList from "../events/components/CustomList";

const AddButton = styled(MDButton)(({ theme }) => ({
  color: blue[500],
  backgroundColor: theme.palette.getContrastText(blue[500]),
  "&:hover": {
    backgroundColor: theme.palette.getContrastText(blue[500]),
  },
  marginLeft: "20px",
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function Entitats() {
  const [isConfigModalDisplayed, setIsConfigModalDisplayed] = useState(false);
  const [isEditModalDisplayed, setIsEditModalDisplayed] = useState(false);
  const [isDeleteModalDisplayed, setIsDeleteModalDisplayed] = useState(false);
  const [isImportModalDisplayed, setIsImportModalDisplayed] = useState(false);

  const Address = ({ address, town, zip_code }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {town}
      </MDTypography>
      <MDTypography variant="caption">
        {address}, {zip_code}
      </MDTypography>
    </MDBox>
  );

  Address.propTypes = {
    address: PropTypes.string.isRequired,
    town: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
  };

  const { send, response, isLoading, error } = useAuthFetch();
  const [entities, setEntities] = useState();
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [importFile, setImportFile] = useState(null);

  const reloadEntities = () => {
    send("get", "/entities", {}, {});
  };

  useEffect(() => {
    send("get", "/entities", {}, {});
  }, []);

  useEffect(() => {
    console.log(response);
    if (response != null) {
      setEntities(
        response.map((entity) => ({
          entitat: entity.name,
          nif: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {entity.nif}
            </MDTypography>
          ),

          address: (
            <Address address={entity.address} town={entity.town} zip_code={entity.zip_code} />
          ),
          province: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {entity.province}
            </MDTypography>
          ),
          phone: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {entity.phone}
            </MDTypography>
          ),
          opcions: (
            <MDBox style={{ display: "inline-block" }}>
              <EditIcon
                fontSize="medium"
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => {
                  setSelectedEntity(entity);
                  setIsEditModalDisplayed(true);
                }}
              />
              <DeleteIcon
                fontSize="medium"
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => {
                  setSelectedEntity(entity);
                  setIsDeleteModalDisplayed(true);
                }}
              />
            </MDBox>
          ),
        }))
      );
    }
  }, [response]);

  const headers2 = [
    { Header: "entitat", accessor: "entitat", width: "40%", align: "left" },
    { Header: "nif", accessor: "nif", align: "left" },
    { Header: "address", accessor: "address", align: "left" },
    { Header: "province", accessor: "province", align: "left" },
    { Header: "phone", accessor: "phone", align: "left" },
    { Header: "Opcions", accessor: "opcions", width: "12%" },
  ];

  const handleFileUpload = async (event) => {
    try {
      console.log("FILE ATTACHED:");
      console.log(event.target.files[0]);
      setImportFile(event.target.files[0]);
      setIsImportModalDisplayed(true);
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <MDTypography variant="h6" color="white">
                    Entitats
                  </MDTypography>
                  <div style={{ marginLeft: "auto" }}>
                    <AddButton
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => setIsConfigModalDisplayed(true)}
                    >
                      Nova entitat
                    </AddButton>
                    <AddButton
                      component="label"
                      variant="outlined"
                      accept=".csv"
                      startIcon={<FileUploadIcon />}
                    >
                      Importar entitats
                      <VisuallyHiddenInput
                        type="file"
                        accept=".csv"
                        onChange={handleFileUpload}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                    </AddButton>
                  </div>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <div>
                  <CustomList
                    headers={headers2}
                    data={entities ? entities : []}
                    rowsPerPageOptions={[5, 10, 20]}
                  />
                </div>
                {/*<DataTable
                  table={{
                    columns: [
                      { Header: "entitat", accessor: "entitat", width: "40%", align: "left" },
                      { Header: "nif", accessor: "nif", align: "left" },
                      { Header: "address", accessor: "address", align: "left" },
                      { Header: "province", accessor: "province", align: "left" },
                      { Header: "phone", accessor: "phone", align: "left" },
                      { Header: "Opcions", accessor: "opcions", width: "12%" },
                    ],
                    rows: entities ? entities : [],
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />*/}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ConfigModal
        open={isConfigModalDisplayed}
        handleClose={() => setIsConfigModalDisplayed(false)}
        onEntitiesUpdated={() => reloadEntities()}
      />
      <EditModal
        open={isEditModalDisplayed}
        entitySelected={selectedEntity}
        handleClose={() => setIsEditModalDisplayed(false)}
        onEntitiesUpdated={() => reloadEntities()}
      />
      <DeleteModal
        open={isDeleteModalDisplayed}
        entitySelected={selectedEntity}
        handleClose={() => setIsDeleteModalDisplayed(false)}
        onEntitiesUpdated={() => reloadEntities()}
      />
      <ImportModal
        open={isImportModalDisplayed}
        importFile={importFile}
        handleClose={() => setIsImportModalDisplayed(false)}
        onEntitiesUpdated={() => reloadEntities()}
      />
    </DashboardLayout>
  );
}

export default Entitats;
