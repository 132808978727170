import React, { useState, useEffect } from "react";
import { IconButton, DialogTitle, Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useAuthFetch } from "hooks";

import LinearProgressWithLabel from "components/LinearProgressWithLabel";
import { Label } from "@mui/icons-material";

function ImportModal({ open, handleClose, importFile, onEntitiesUpdated }) {
  const {
    send: sendEntity,
    response: responseEntity,
    isLoading,
    error: sendError,
  } = useAuthFetch();
  const [entities, setEntities] = useState([]);
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    sendOneEntity();
  }, [responseEntity]);

  useEffect(() => {
    if (sendError != undefined) {
      console.log(sendError);
      setError(true);
    }
  }, [sendError]);

  useEffect(() => {
    if (entities != undefined) {
      sendOneEntity();
    }
  }, [entities]);

  function sendOneEntity() {
    console.log("Sending entity " + index + "/" + entities.length);
    if (index < entities.length) {
      const entity = entities[index];
      console.log(entity);
      setIndex(index + 1);
      sendEntity("post", "/entities", entity, {});
    } else {
      setIndex(0);
      close();
    }
  }

  function parseCSV(content) {
    const arr = content.split("\n");
    const headers = arr[0].split(",");
    var objs = [];
    for (var i = 1; i < arr.length; i++) {
      const data = arr[i].split(",");
      var obj = {};
      for (var j = 0; j < data.length; j++) {
        obj[headers[j].trim()] = data[j].trim();
      }
      objs.push(obj);
    }
    return objs;
  }

  useEffect(() => {
    if (open && importFile != undefined) {
      setError(false);
      setIndex(0);
      importFile.text().then((content) => {
        console.log("CONTENT: ");
        console.log(content);
        console.log("PARSED: ");
        console.log(parseCSV(content));
        setEntities(parseCSV(content));
      });
    }
  }, [open]);

  function close() {
    onEntitiesUpdated();

    // close modal
    handleClose();
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle sx={{ fontWeight: "bold", alignSelf: "center" }}>
          Important entitats
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <LinearProgressWithLabel
            value={
              entities == undefined || entities.length == 0 ? 0 : (index / entities.length) * 100
            }
            color="inherit"
          />
          <p hidden={!error}> Error: fitxer CSV amb format incorrecte. </p>
        </DialogContent>
      </Dialog>
    </>
  );
}

// PropTypes validation
ImportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onEntitiesUpdated: PropTypes.func.isRequired,
  importFile: PropTypes.object,
};

export default ImportModal;
