/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import brandWhite from "assets/images/PIMPAM3.jpg";
import bgImage from "assets/images/sardanes2noticia.jpg";
import useLogin from "hooks/useLogin";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, isLoading, error } = useLogin();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSend = (e) => {
    e.preventDefault();
    login(email, password);
  };

  const handleKeyDown = (e) => {
    // Check if the Enter key is pressed (key code 13)
    if (e.key === "Enter") {
      // Check if both email and password are set
      if (email.trim() !== "" && password.trim() !== "") {
        handleSend(e);
      }
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="warning"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDBox component={NavLink} to="/" display="flex" alignItems="center">
            {brandWhite && <MDBox component="img" src={brandWhite} alt="Logotip" width="100%" />}
          </MDBox>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Correu"
                fullWidth
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Contrasenya"
                fullWidth
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;{"Recorda'm"}
              </MDTypography>
            </MDBox>
            <MDBox mt={0} mb={-2} textAlign="start">
              <MDTypography variant="button" color="error">
                {error}
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="warning"
                fullWidth
                onClick={handleSend}
                disabled={isLoading ? true : false}
              >
                Accedir
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
