/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import PieChart from "examples/Charts/PieChart";

import { useAuthFetch } from "hooks";

function gen_formatge(events, entitats) {
  let formatge = {
    labels: [],
    datasets: {
      label: "Events",
      backgroundColors: ["primary", "secondary", "info", "success", "warning", "error", "dark"],
      data: [],
    },
  };
  for (let i = 0; i < entitats.length; i++) {
    let nom = entitats[i]["name"];
    formatge["labels"].push(nom);
    formatge["datasets"]["data"].push(
      events.filter((event) => event["entity"]["name"] == nom).length
    );
  }

  return formatge;
}

function EntitiesPieChart() {
  const [formatge, setFormatge] = useState([]);
  const [events, setEvents] = useState([]);
  const { send: sendEvents, response: responseEvents, isLoading, error } = useAuthFetch();
  const { send: sendEntities, response: responseEntities } = useAuthFetch();

  useEffect(() => {
    sendEvents(
      "get",
      "/events",
      {},
      {
        start: new Date(new Date().getFullYear().toString()).toISOString(),
        end: new Date((new Date().getFullYear() + 1).toString()).toISOString(),
        order: "asc",
      }
    );
  }, []);

  useEffect(() => {
    sendEntities("get", "/entities", {}, {});
  }, []);

  useEffect(() => {
    if (responseEvents != null && responseEntities != null) {
      setFormatge(gen_formatge(responseEvents, responseEntities));
    } else {
      setFormatge(gen_formatge([], []));
    }
  }, [responseEvents, responseEntities]);

  console.log(events);
  return (
    <PieChart
      icon={{ color: "info", component: "groups" }}
      title="Events per entitat"
      description="Total d'events organitzats per cada entitat"
      chart={formatge}
    />
  );
}

export default EntitiesPieChart;
