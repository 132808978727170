/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import download from "downloadjs";
import * as React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditModal from "layouts/calendar/components/EditModal";
import DeleteModal from "layouts/calendar/components/DeleteModal";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

//MUI icons
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MultipleSelectCheckmarks from "./components/MultipleSelectCheckmarks";
import MDButton from "components/MDButton";
import { useAuthFetch } from "hooks";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import MDBadge from "components/MDBadge";
import { FilterList } from "@mui/icons-material";
import CustomList from "./components/CustomList";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Tables() {
  const [personName, setPersonName] = React.useState([]);
  const [nombresEntidades, setNombresEntidades] = React.useState(["Entitats..."]);
  const [eventos, setEventos] = React.useState([]);
  const [event, setEvent] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { send: sendEntitats, response: responseEntitats, error: errorEntitats } = useAuthFetch();
  const {
    send: sendEvents,
    response: responseEvents,
    error: errorEvents,
    isLoading: isLoadingEvents,
  } = useAuthFetch();

  //DownloadPDF logic
  const createPDF = (entityId, eventId, fileName) => {
    axios
      .post(
        process.env.REACT_APP_BASE_ADDRESS + "/reports",
        {
          type: "SGAE_EVENT",
          event: {
            id: eventId,
          },
          entity: {
            id: entityId,
          },
        },
        {
          timeout: 5000,
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        getPDF(response.data.id, fileName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPDF = (reportId, fileName) => {
    axios
      .get(process.env.REACT_APP_BASE_ADDRESS + "/reports/" + reportId, {
        responseType: "blob",
        withCredentials: true,
        timeout: 5000,
      })
      .then((response) => {
        const content = response.headers["content-type"];
        download(response.data, fileName, content);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Filter  logic
  const [initialDate, setInitialDate] = React.useState(dayjs().set("date", 1));
  const [finalDate, setFinalDate] = React.useState(dayjs().endOf("month"));
  const [entitatsFiltre, setEntitatsFiltre] = React.useState("");

  const handleFilter = () => {
    sendEvents(
      "get",
      "/events",
      {},
      {
        start: initialDate.format("YYYY-MM-DD"),
        end: finalDate.format("YYYY-MM-DD"),
        entity: entitatsFiltre.toString(),
      }
    );
  };

  const reloadEvents = () => {
    sendEvents(
      "get",
      "/events",
      {},
      {
        start: initialDate.format("YYYY-MM-DD"),
        end: finalDate.format("YYYY-MM-DD"),
      }
    );
  };

  React.useEffect(() => {
    sendEntitats("get", "/entities", {}, {});
    sendEvents(
      "get",
      "/events",
      {},
      {
        start: initialDate.format("YYYY-MM-DD"),
        end: finalDate.format("YYYY-MM-DD"),
      }
    );
  }, []);

  React.useEffect(() => {
    if (!errorEntitats && responseEntitats) {
      setNombresEntidades(responseEntitats.map((entidad) => entidad.name));
    }
  }, [responseEntitats]);

  React.useEffect(() => {
    if (!errorEvents && responseEvents) {
      console.log("seteamos eventos");
      console.log(responseEvents);
      setEventos(
        responseEvents.map((event) => {
          return {
            tipus: event.type,
            aso: event.entity.name,
            data: dayjs(event.datetime).format("DD/MM/YYYY, HH:mm"),
            opcions: (
              <MDBox style={{ display: "inline-block" }}>
                <SimCardDownloadIcon
                  fontSize="medium"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    createPDF(
                      event.entity.id,
                      event.id,
                      event.type +
                        " de " +
                        event.entity.name +
                        " " +
                        dayjs(event.datetime).format("DD/MM/YYYY")
                    )
                  }
                />
                <EditIcon
                  fontSize="medium"
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={() => {
                    setIsEditModalOpen(true);
                    setSelectedEvent(event);
                    setEvent(event);
                  }}
                />
                <DeleteIcon
                  fontSize="medium"
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                    setSelectedEvent(event);
                    setEvent(event);
                  }}
                />
              </MDBox>
            ),
          };
        })
      );
    }
  }, [responseEvents]);

  const headers = [
    { Header: "ID", accessor: "ID", width: "20%" },
    { Header: "Name", accessor: "Name", width: "20%" },
    { Header: "Age", accessor: "Age", width: "20%" },
    { Header: "Email", accessor: "Email", width: "20%" },
    { Header: "Actions", accessor: "Actions", width: "20%" },
  ];

  const headers2 = [
    { Header: "Esdeveniment", accessor: "tipus", width: "25%" },
    { Header: "Associació", accessor: "aso", width: "30%" },
    { Header: "Data", accessor: "data", width: "33%" },
    { Header: "Opcions", accessor: "opcions", width: "12%" },
  ];
  const data = [
    {
      ID: 1,
      Name: "John",
      Age: 30,
      Email: "john@example.com",
      Actions: (
        <div>
          <button onClick={() => handleEdit(1)}>Edit</button>
          <button onClick={() => handleDelete(1)}>Delete</button>
        </div>
      ),
    },
    {
      ID: 2,
      Name: "Jane",
      Age: 25,
      Email: "jane@example.com",
      Actions: (
        <div>
          <button onClick={() => handleEdit(2)}>Edit</button>
          <button onClick={() => handleDelete(2)}>Delete</button>
        </div>
      ),
    },
    {
      ID: 3,
      Name: "Bob",
      Age: 40,
      Email: "bob@example.com",
      Actions: (
        <div>
          <button onClick={() => handleEdit(3)}>Edit</button>
          <button onClick={() => handleDelete(3)}>Delete</button>
        </div>
      ),
    },
  ];

  const handleEdit = (id) => {
    console.log("Edit:", id);
    // Add your edit logic here
  };

  const handleDelete = (id) => {
    console.log("Delete:", id);
    // Add your delete logic here
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Events
                </MDTypography>
              </MDBox>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  gap: "1rem",
                  justifyItems: "stretch",
                  marginTop: 15,
                }}
              >
                <div style={{ display: "flex", gap: "inherit" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker
                      value={initialDate}
                      onChange={(newDate) => setInitialDate(newDate)}
                      // sx={{ minWidth: "250px" }}
                      label={"Des de"}
                      views={["year", "month", "day"]}
                    />
                    <DatePicker
                      value={finalDate}
                      onChange={(newDate) => setFinalDate(newDate)}
                      // sx={{ minWidth: "250px" }}
                      label={"Fins a"}
                      views={["year", "month", "day"]}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <MultipleSelectCheckmarks
                    values={nombresEntidades}
                    onChange={(e) => setEntitatsFiltre(e)}
                  />
                </div>
                <div>
                  <MDButton variant="outlined" color="info" onClick={handleFilter}>
                    Filtrar
                  </MDButton>
                </div>
              </div>
              <MDBox pt={3}>
                {isLoadingEvents ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "20rem",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    <CustomList
                      headers={headers2}
                      data={eventos}
                      rowsPerPageOptions={[5, 10, 20]}
                    />
                  </div>
                )}
                <EditModal
                  open={isEditModalOpen}
                  eventSelected={event}
                  handleClose={() => setIsEditModalOpen(false)}
                  onEventsUpdated={() => reloadEvents()}
                  onUpdateEvent={() => setIsModalOpen(true)}
                />
                <DeleteModal
                  open={isDeleteModalOpen}
                  eventSelected={selectedEvent}
                  handleClose={() => setIsDeleteModalOpen(false)}
                  onEventsUpdated={() => reloadEvents()}
                  onUpdateEvent={() => setIsModalOpen(true)}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
