import { useState } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router";
import useAuthContext from "./useAuthContext";

const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [, dispatch] = useAuthContext();
  const navigate = useNavigate();

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_ADDRESS + "/auth/login",
        {
          email,
          password,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        dispatch({ type: "LOGIN", payload: { email, name: "Pepe" } });
        setIsLoading(false);
        navigate("/panell-de-control");
      } else {
        setIsLoading(false);
        setError("Request failed with status " + response.status);
      }
    } catch (err) {
      setIsLoading(false);
      setError(
        err.response?.data.msg ? err.response.data.msg : `An error occurred: ${err.message}`
      );
    }
  };
  return { login, isLoading, error };
};

export default useLogin;
