import { createContext, useContext, useReducer, useMemo, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";

export const AuthContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        isLoading: false,
        isAuthenticated: action.payload.isAuthenticated,
        email: action.payload.email,
        name: action.payload.name,
      };
    case "LOGIN":
      localStorage.setItem("email", action.payload.email);
      return {
        ...state,
        isAuthenticated: true,
        email: action.payload.email,
        name: action.payload.name,
      };
    case "LOGOUT":
      localStorage.removeItem("email");
      return {
        ...state,
        isAuthenticated: false,
        email: null,
        name: null,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const initialState = {
    isLoading: true,
    isAuthenticated: true,
    email: null,
    name: null,
  };

  // Typechecking props for the MaterialUIControllerProvider
  AuthContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [state, dispatch], [state, dispatch]);

  useEffect(() => {
    const email = localStorage.getItem("email");
    console.log(email);
    if (!email) {
      console.warn("No email found, checking for cookies!");
      axios
        .get("http://localhost:3030/test/testAuth", { withCredentials: true })
        .then((response) => {
          console.warn("Cookies found!");
          localStorage.setItem("email", response.data);
          dispatch({
            type: "INIT",
            payload: { isAuthenticated: true, email: response.data, name: "Pepe" },
          });
        })
        .catch((error) => {
          console.warn("No cookies found!");
          dispatch({ type: "INIT", payload: { isAuthenticated: false, email: null, name: null } });
        });
    } else {
      console.info("Email found, you're logged in");
      dispatch({ type: "INIT", payload: { isAuthenticated: true, email, name: "Pepe" } });
    }
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
