const EventType = {
  Aplec: "APLEC",
  Ballada: "BALLADA",
  Concert: "CONCERT",
  Concurs: "CONCURS",
  Altres: "ALTRES_ACTES",
};

const EventFile = {
  id: undefined,
  file_name: undefined,
};

const ConcertType = {
  Petit: "SMALL",
  Gran: "BIG",
};

const Event = {
  type: EventType,
  property: undefined,
  address: undefined,
  town: undefined,
  province: undefined,
  datetime: Date,
  entity: {
    id: undefined,
  },
  orchestra: undefined,
  num_orchestras: undefined,
  free: undefined,
  revenue: undefined,
  concert_type: ConcertType,
};

export { EventType, Event, EventFile, ConcertType };
