import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Delete,
  Theme,
  Button,
  useTheme,
  IconButton,
  DialogTitle,
  Box,
  TextField,
  Dialog,
  Stack,
  DialogContent,
} from "@mui/material";
import { EventType, EventFile, Event } from "layouts/calendar/models/Event";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useAuthFetch } from "hooks";
import { blue, green } from "@mui/material/colors";
import { Send } from "@mui/icons-material";

import LoadingBackdrop from "components/LoadingBackdrop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const SendButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]), // para obtener blanco
  backgroundColor: green[500],
  "&:hover": {
    backgroundColor: green[700],
  },
}));
const entityTemplate = {
  name: "",
  nif: "",
  address: "",
  town: "",
  province: "",
  zip_code: "",
  phone: null,
  mail: "",
  bank_entity: "",
  bank_branch: "",
  bank_iban: "",
};
function ConfigModal({ open, handleClose, onEntitiesUpdated }) {
  const [entity, setEntity] = useState(entityTemplate);

  const [loading, setLoading] = useState(false);
  const { send: sendEntity, response: responseEntity, isLoading } = useAuthFetch();

  const MARGIN_BOTTOM = 3;

  function addEntity() {
    setLoading(true);
    console.log(entity);
    sendEntity("post", "/entities", entity, {});
  }

  useEffect(() => {
    close();
  }, [responseEntity]);

  function close() {
    // reset fields
    setEntity(entityTemplate);

    // refresh calendars
    onEntitiesUpdated();

    // close loading backdrop
    setLoading(false);

    // close modal
    handleClose();
  }

  return (
    <>
      <LoadingBackdrop open={loading} handleOnClick={() => {}} />
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle sx={{ fontWeight: "bold", alignSelf: "center" }}>
          Afegir nova entitat
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <form
            onSubmit={async (e) => {
              console.log("SUBMITTEADO");
              e.preventDefault(); // Prevent the default form submission behavior
              addEntity();
            }}
          >
            <p style={{ marginBottom: 10 }}> {"Dades de l'entitat"} </p>
            <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Nom"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    };
                  })
                }
                value={entity.name}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="NIF"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      nif: e.target.value,
                    };
                  })
                }
                value={entity.nif}
                fullWidth
                required
                sx={{ marginBottom: MARGIN_BOTTOM }}
              />
            </Stack>
            <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Telefon"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      phone: e.target.value,
                    };
                  })
                }
                value={entity.phone}
                fullWidth
                required
              />
            </Stack>
            <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Correu electrònic"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      mail: e.target.value,
                    };
                  })
                }
                value={entity.mail}
                fullWidth
                required
              />
            </Stack>
            <p style={{ marginBottom: 10 }}> {"Ubicació de l'entitat"} </p>
            <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Adreça"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      address: e.target.value,
                    };
                  })
                }
                value={entity.address}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Ciutat/Poble"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      town: e.target.value,
                    };
                  })
                }
                value={entity.town}
                fullWidth
                required
                sx={{ marginBottom: MARGIN_BOTTOM }}
              />
            </Stack>
            <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Provincia"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      province: e.target.value,
                    };
                  })
                }
                value={entity.province}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="ZIP"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      zip_code: e.target.value,
                    };
                  })
                }
                value={entity.zip_code}
                fullWidth
                required
              />
            </Stack>
            <p style={{ marginBottom: 10 }}> {"Dades bancàries de l'entitat"} </p>
            <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Entitat"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      bank_entity: e.target.value,
                    };
                  })
                }
                value={entity.bank_entity}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Sucursal"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      bank_branch: e.target.value,
                    };
                  })
                }
                value={entity.bank_branch}
                fullWidth
                required
                sx={{ marginBottom: MARGIN_BOTTOM }}
              />
            </Stack>
            <Stack spacing={2} direction="row" sx={{ marginBottom: MARGIN_BOTTOM }}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="IBAN"
                onChange={(e) =>
                  setEntity((prev) => {
                    return {
                      ...prev,
                      bank_iban: e.target.value,
                    };
                  })
                }
                value={entity.bank_iban}
                fullWidth
                required
              />
            </Stack>
            <div
              style={{
                marginTop: 4,
                marginRight: 4,
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <SendButton
                type="submit"
                sx={{ position: "center" }}
                variant="contained"
                color="success"
                endIcon={<SendIcon />}
              >
                Registrar entitat
              </SendButton>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

// PropTypes validation
ConfigModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onEntitiesUpdated: PropTypes.func.isRequired,
};

export default ConfigModal;
