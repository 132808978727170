import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      fontSize: "1rem",
    },
  },
};

export default function SingleSelect({ sx, name, values, defValue, onChange }) {
  if (!values) return null;
  const [value, setValue] = React.useState(defValue);

  const handleChange = (event) => {
    const {
      target: { value: val },
    } = event;
    console.log(val);
    setValue(val);
    onChange(val);
  };

  return (
    <FormControl sx={sx}>
      <InputLabel id="single-select-label">{name}</InputLabel>
      <Select
        labelId="single-select-label"
        id="single-select"
        style={{ height: "44.125px" }}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={name} />}
        renderValue={(selected) => values[selected]}
        //MenuProps={MenuProps}
      >
        {values.map((name, index) => (
          <MenuItem key={index} value={index}>
            <ListItemText primary={name} disableTypography={true} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SingleSelect.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  defValue: PropTypes.string,
  onChange: PropTypes.func,
};
