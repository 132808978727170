import React, { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Stack,
  Grid,
  IconButton,
} from "@mui/material";
import { useAuthFetch } from "hooks";
import { styled } from "@mui/material/styles";
import { yellow, red } from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import LoadingBackdrop from "components/LoadingBackdrop";

const MARGIN_LEFT = 12;

const EditButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: yellow[700],
  "&:hover": {
    backgroundColor: yellow[800],
  },
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: red[500],
  "&:hover": {
    backgroundColor: red[700],
  },
}));

const EventModal = ({ isOpen, onRequestClose, onEdit, onDelete, event }) => {
  const { send: sendEvent, response: responseEvent, isLoading, error } = useAuthFetch();
  const [loading, setLoading] = useState(false);
  const [eventBack, setEventBack] = useState();

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str) => {
    return (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).replace(/_/g, " ");
  };

  useEffect(() => {
    if (isOpen == true && event != null) {
      setLoading(true);
      console.log("Event", event?.id);
      sendEvent("get", "/events/" + event?.id, {}, {});
    }
    //sendEvents("post", "/events", {evento objeto}, {})
  }, [isOpen]);

  useEffect(() => {
    console.log("RESPUESTA:");
    console.log(responseEvent);
    if (responseEvent != null && responseEvent.entity != null) {
      setLoading(false);
      setEventBack({
        id: responseEvent.id,
        title:
          capitalizeFirstLetter(String(responseEvent.type)) + " de " + responseEvent.entity.name,
        start: new Date(responseEvent.datetime),
        end: new Date(
          new Date(responseEvent.datetime).setHours(new Date(responseEvent.datetime).getHours() + 1)
        ),
        property: responseEvent.property,
        address: responseEvent.address + ", " + responseEvent.town,
        province: responseEvent.province,
        orchestra: responseEvent.orchestra,
      });
    }
  }, [responseEvent]);

  console.log(eventBack);
  return (
    <>
      <LoadingBackdrop open={loading} handleOnClick={() => {}} />
      <Dialog onClose={onRequestClose} open={isOpen} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle sx={{ fontWeight: "bold", alignSelf: "center" }}>
          {eventBack?.title}
          {/*event?.type + " de " + event?.entity_id*/}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onRequestClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <p style={{ marginLeft: MARGIN_LEFT }}>
            <strong>Local:</strong> {eventBack?.property}
          </p>
          <p style={{ marginLeft: MARGIN_LEFT }}>
            <strong>Ubicació:</strong> {eventBack?.address}
          </p>
          <p style={{ marginLeft: MARGIN_LEFT }}>
            <strong>Provincia:</strong> {eventBack?.province}
          </p>
          <p style={{ marginLeft: MARGIN_LEFT }}>
            <strong>Orquestra o cobla:</strong> {eventBack?.orchestra}
          </p>
          <p style={{ marginLeft: MARGIN_LEFT }}>
            <strong>{"Data d'inici:"}</strong> {eventBack?.start.toLocaleString()}
          </p>
          <p style={{ marginLeft: MARGIN_LEFT }}>
            <strong>Data fi:</strong> {eventBack?.end.toLocaleString()}
          </p>
          <Stack
            spacing={23}
            direction="row"
            sx={{ marginLeft: 2, marginRight: 2, marginTop: 4, marginBottom: 2 }}
          >
            <Grid item xs={3}>
              <EditButton
                sx={{ alignSelf: "left" }}
                component="label"
                variant="contained"
                color="success"
                startIcon={<EditIcon />}
                onClick={onEdit}
              >
                Editar dades
              </EditButton>
            </Grid>
            <Grid item xs={4}>
              <DeleteButton
                sx={{ position: "center" }}
                variant="contained"
                color="success"
                endIcon={<DeleteIcon />}
                onClick={onDelete}
              >
                Eliminar event
              </DeleteButton>
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

EventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired, // Add propTypes
  onRequestClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  event: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default EventModal;
