import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

function LoadingBackdrop({ open, handleOnClick }) {
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
        open={open}
        onClick={handleOnClick}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

LoadingBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default LoadingBackdrop;
