import PropTypes from "prop-types";
import React, { useState } from "react";

import "./CustomList.css";

const CustomList = ({ headers, data, rowsPerPageOptions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [searchTerm, setSearchTerm] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // reset current page when changing rows per page
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // reset current page when changing search term
  };

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    } else if (sortConfig.key === key && sortConfig.direction === "desc") {
      key = null;
    }
    setSortConfig({ key, direction });
  };

  function trimArrayAroundPosition(array, position, amount) {
    if (array.length < 6) return array;
    const start = Math.max(0, position - (amount + 1));
    const end = Math.min(array.length, position + amount + 1);
    return array.slice(start, end);
  }

  const sortedData = () => {
    const sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  };

  const filteredData = () => {
    return sortedData().filter((item) => {
      return Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData().slice(indexOfFirstRow, indexOfLastRow);
  const numberOfPages = Math.ceil(filteredData().length / rowsPerPage);
  const paginationList = Array.from({ length: numberOfPages }, (_, index) => index + 1);
  console.log(data);
  return (
    <div className="table-container">
      <div className="table-options">
        <div>
          <select
            className="object_per_page-input"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          >
            {rowsPerPageOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <span className="object_per_page-text ">entries per page</span>
        </div>
        <div className="input-container">
          <input
            className="search-input"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
          />
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                style={{ width: header.width }}
                key={index}
                onClick={() => sortData(header.accessor)}
              >
                {header.Header}
                {sortConfig.key === header.accessor && (
                  <span>{sortConfig.direction === "asc" ? " 🔼" : " 🔽"}</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((item, index) => (
            <tr key={index}>
              {headers.map((header, index) => (
                <td key={index} style={{ width: header.width }}>
                  {item[header.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {numberOfPages > 1 && (
        <div className="pagination-container">
          {currentPage > 1 && (
            <button
              onClick={() => handleChangePage(currentPage > 1 ? currentPage - 1 : currentPage)}
              style={{ fontWeight: "600" }}
              className="pagination-button"
            >
              {"<"}
            </button>
          )}
          {trimArrayAroundPosition(paginationList, currentPage, 2).map((number) => {
            return (
              <button
                key={number}
                className={
                  number == currentPage ? "pagination-button-selected" : "pagination-button"
                }
                onClick={() => handleChangePage(number)}
              >
                {number}
              </button>
            );
          })}
          {currentPage < numberOfPages && (
            <button
              style={{ fontWeight: "600" }}
              className="pagination-button"
              onClick={() =>
                handleChangePage(currentPage < numberOfPages ? currentPage + 1 : currentPage)
              }
            >
              {">"}
            </button>
          )}

          {/* <span>
          Page {currentPage} of {Math.ceil(filteredData().length / rowsPerPage)}
        </span>
        <button
          onClick={() => handleChangePage(currentPage > 1 ? currentPage - 1 : currentPage)}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <button
          onClick={() =>
            handleChangePage(
              currentPage < Math.ceil(filteredData().length / rowsPerPage)
                ? currentPage + 1
                : currentPage
            )
          }
          disabled={currentPage === Math.ceil(filteredData().length / rowsPerPage)}
        >
          Next
        </button> */}
        </div>
      )}
    </div>
  );
};

export default CustomList;

CustomList.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
};
