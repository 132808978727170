import React, { useState, useEffect } from "react";
import { Button, IconButton, DialogTitle, Dialog, Stack, DialogContent, Grid } from "@mui/material";
import "dayjs/locale/ca";
import "dayjs/locale/es";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useAuthFetch } from "hooks";
import { red, grey } from "@mui/material/colors";

import LoadingBackdrop from "components/LoadingBackdrop";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ReturnButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]), // para obtener blanco
  backgroundColor: grey[500],
  "&:hover": {
    backgroundColor: grey[700],
  },
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]), // para obtener blanco
  backgroundColor: red[500],
  "&:hover": {
    backgroundColor: red[700],
  },
}));
function DeleteModal({ open, handleClose, onEventsUpdated, onUpdateEvent, eventSelected }) {
  const [loading, setLoading] = useState(false);
  const { send: sendEvent, response: responseEvent } = useAuthFetch();

  const MARGIN_BOTTOM = 3;

  useEffect(() => {
    close();
  }, [responseEvent]);

  function close() {
    // refresh calendars
    onEventsUpdated();

    // close loading backdrop
    setLoading(false);

    // close modal
    handleClose();
    //onUpdateEvent();
  }

  function deleteEvent() {
    //await api.deleteCalendar(token, calendarBeingDeleted.id)
    setLoading(true);
    sendEvent("delete", "/events/" + eventSelected?.id, {}, {});

    // refresh calendars
    onEventsUpdated();
  }

  return (
    <>
      <LoadingBackdrop open={loading} handleOnClick={() => {}} />
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"xs"}>
        <DialogTitle sx={{ fontWeight: "bold", alignSelf: "center", marginLeft: 3 }}>
          {"Estàs segur/a d'eliminar permanenment l'esdeveniment?"}
        </DialogTitle>
        <DialogContent>
          <Stack
            spacing={15}
            direction="row"
            sx={{ marginLeft: 4, marginRight: 4, marginTop: 4, marginBottom: 2 }}
          >
            <Grid item>
              <ReturnButton
                sx={{ alignSelf: "left" }}
                component="label"
                variant="contained"
                fullWidth
                onClick={handleClose}
              >
                Cancelar
              </ReturnButton>
            </Grid>
            <Grid item>
              <DeleteButton
                type="submit"
                sx={{ position: "center" }}
                variant="contained"
                fullWidth
                onClick={deleteEvent}
              >
                Confirmar
              </DeleteButton>
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

// PropTypes validation
DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onEventsUpdated: PropTypes.func.isRequired,
  onUpdateEvent: PropTypes.func.isRequired,
  eventSelected: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default DeleteModal;
